var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ReportDownload', {
    attrs: {
      "loading": _vm.loadingAction.generateAssetReport,
      "generate-report": _vm.generateAssetReport
    },
    on: {
      "generate": _vm.onGenerateReport
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('router-link', {
          staticClass: "btn btn-light mr-3",
          attrs: {
            "active-class": "active",
            "to": {
              name: 'asset-reports',
              params: {
                id: _vm.asset._id
              }
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-fw fa-arrow-left"
        }), _vm._v(" Back to Reports")])];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }