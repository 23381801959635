<template>
  <div>
    <ReportDownload :loading="loadingAction.generateAssetReport" :generate-report="generateAssetReport" @generate="onGenerateReport">
      <template v-slot:actions>
        <router-link active-class="active" :to="{ name: 'asset-reports', params: { id: asset._id } }" class="btn btn-light mr-3"
          ><i class="fa fa-fw fa-arrow-left"></i> Back to Reports</router-link
        >
      </template>
    </ReportDownload>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import ReportDownload from '@/components/reports/ReportDownload';

export default {
  name: 'AssetReportDownload',
  components: {
    ReportDownload
  },
  computed: {
    ...mapGetters({
      asset: 'asset/asset',
      loadingAction: 'report/loadingAction',
      report: 'report/report'
    })
  },
  methods: {
    ...mapActions({
      generateAssetReport: 'report/generateAssetReport'
    }),
    onGenerateReport(report) {
      this.$router.replace({ name: 'asset-reports-download', params: this.$route.params, query: { ...this.$route.query, id: report._id } });
    }
  }
};
</script>
